/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { Component, useContext } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import GetInTouch from "~components/GetInTouch";
import Layout from "~components/Layout";
import Newsletter from "~components/Newsletter";
import SEO from "~components/SEO";
import SwiperCarousel from "~components/SwiperCarousel.jsx";

class NowThemPageComponent extends Component {
  componentDidMount() {
    const { appContext } = this.props;

    appContext.setLanguageWidgetColor(`black`);
    appContext.setNavColor(`black`);
  }

  componentWillUnmount() {
    const { appContext } = this.props;

    appContext.setLanguageWidgetColor(`white`);
    appContext.setNavColor(`white`);
  }

  //

  splitStringToParagraphs = string => {
    const splitText = string.split(/\r?\n/);
    const jsx = [];

    splitText.forEach((row, index) => {
      const rowIndex = index;

      if (row !== ``) {
        jsx.push(
          <p key={`split-text-${rowIndex}`} className="relative block mb-2">
            {row}
          </p>
        );
      }
    });

    return jsx;
  };

  //

  render() {
    const { documentContext, frontmatter, location } = this.props;
    const { device } = documentContext;

    return (
      <>
        <SEO
          customTitle={frontmatter.title}
          customDescription={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />

        <Layout
          className="nowthem-page w-full relative xs:pt-12"
          location={location}
        >
          <section className="relative pt-16">
            <div className="relative grid">
              <article className="grid-end-17 grid-start-4 sm:grid-end-22 sm:grid-start-2 xs:pt-4">
                <h1 className="project-page__tagline animation-appear-right animation-delay-4 mt-6 f1">
                  {frontmatter.heading}
                </h1>
              </article>
            </div>
          </section>

          {(device === `desktop` && (
            <div className="project-page__content project-page__content--desktop relative">
              {frontmatter.components &&
                frontmatter.components.map((component, index) => {
                  const key = `section-${index}`;

                  let componentJSX;

                  switch (component.type) {
                    case `mixedCarousel`:
                      if (!component?.carouselItem?.[0]) {
                        componentJSX = <></>;
                      } else {
                        componentJSX = (
                          <section key={key} className="relative mt-8 mb-8">
                            <div className="grid">
                              <div className="grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative">
                                <SwiperCarousel
                                  className="w-full h-full relative block"
                                  options={{
                                    autoplay: {
                                      delay: 2200
                                    }
                                  }}
                                  slides={component.carouselItem.map(
                                    (item, itemIndex) => {
                                      const itemkey = `${key}-carousel-${itemIndex}`;

                                      return (
                                        <li
                                          key={itemkey}
                                          className="w-full h-full relative block"
                                        >
                                          {(item?.carouselImage?.childImageSharp
                                            ?.fluid && (
                                            <Img
                                              className="w-full h-full relative block object-cover"
                                              fluid={
                                                item.carouselImage
                                                  .childImageSharp.fluid
                                              }
                                              alt="Carousel Item"
                                            />
                                          )) || (
                                            <>
                                              {item && item.carouselVideoUrl && (
                                                <video
                                                  className="w-full h-full relative block object-cover"
                                                  autoPlay
                                                  loop
                                                  muted
                                                  playsInline
                                                >
                                                  <source
                                                    src={item.carouselVideoUrl}
                                                  />
                                                </video>
                                              )}
                                            </>
                                          )}
                                        </li>
                                      );
                                    }
                                  )}
                                />
                              </div>
                            </div>
                          </section>
                        );
                      }

                      break;

                    case `simpleImage`:
                      componentJSX = (
                        <section
                          key={key}
                          className="animation-appear animation-delay-5 relative mt-8 mb-8"
                        >
                          <div className="grid">
                            <figure className="grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative">
                              {component?.simpleImageSource?.childImageSharp
                                ?.fluid && (
                                <Img
                                  className="w-full relative block"
                                  fluid={
                                    component.simpleImageSource.childImageSharp
                                      .fluid
                                  }
                                  alt="Gallery Item"
                                />
                              )}
                            </figure>
                          </div>
                        </section>
                      );

                      break;

                    case `simpleText`:
                      componentJSX = (
                        <section
                          key={key}
                          className={`animation-appear-right animation-delay-2 relative pt-12 pb-24 text-${component.simpleTextFontColor}`}
                          style={{
                            backgroundColor: component.simpleTextBackgroundColor
                          }}
                        >
                          <article className="grid">
                            {component.simpleTextHeading && (
                              <h3 className="grid-end-16 grid-start-4 f2">
                                {component.simpleTextHeading}
                              </h3>
                            )}

                            {component.simpleTextContent && (
                              <div className="grid-end-16 grid-start-4 mt-6 b2">
                                {this.splitStringToParagraphs(
                                  component.simpleTextContent
                                )}
                              </div>
                            )}
                          </article>
                        </section>
                      );

                      break;

                    case `largeText`:
                      componentJSX = (
                        <section
                          key={key}
                          className="animation-appear animation-delay-5 relative mt-24 sm:mt-12 mb-48 sm:mb-12"
                        >
                          <article className="grid">
                            {component.largeTextHeading && (
                              <h3 className="grid-end-10 grid-start-5 sm:grid-end-22 sm:grid-start-2 mb-4 b1">
                                — {component.largeTextHeading}
                              </h3>
                            )}

                            {component.largeTextContent && (
                              <h2
                                className="grid-end-16 grid-start-4 sm:grid-end-22 sm:grid-start-2 f1 whitespace-pre-wrap"
                                dangerouslySetInnerHTML={{
                                  __html: component.largeTextContent
                                }}
                              ></h2>
                            )}

                            {component.largeTextSubheading && (
                              <h3 className="grid-end-4 grid-start-21 sm:grid-end-22 sm:grid-start-2 flex items-end sm:mt-12 b2">
                                {component.largeTextSubheading}
                              </h3>
                            )}
                          </article>
                        </section>
                      );

                      break;

                    case `sideBySideText`:
                      componentJSX = (
                        <section
                          key={key}
                          className="animation-appear animation-delay-5 relative mt-16 sm:mt-0 mb-24 sm:mb-12"
                        >
                          <article className="grid">
                            {component.sideTextLeft && (
                              <h3 className="grid-end-6 grid-start-5 sm:grid-end-22 sm:grid-start-2 f4">
                                {component.sideTextLeft}
                              </h3>
                            )}

                            {component.sideTextRight && (
                              <p
                                className="grid-end-8 grid-start-17 sm:grid-end-22 sm:grid-start-2 sm:mt-12 b2 whitespace-pre-wrap"
                                dangerouslySetInnerHTML={{
                                  __html: component.sideTextRight
                                }}
                              ></p>
                            )}
                          </article>
                        </section>
                      );

                      break;

                    case `video`:
                      componentJSX = (
                        <section
                          key={key}
                          className="animation-appear animation-delay-5 relative mt-8 mb-8"
                        >
                          <div className="grid">
                            <div className="animation-appear-right animation-delay-5 grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative">
                              <video
                                className="w-full relative block object-cover"
                                autoPlay
                                loop
                                muted
                                playsInline
                              >
                                <source src={component.videoUrl} />
                              </video>
                            </div>
                          </div>
                        </section>
                      );

                      break;

                    default:
                      componentJSX = <></>;
                      break;
                  }

                  return componentJSX;
                })}
            </div>
          )) || (
            <div className="project-page__content project-page__content--touch relative">
              {frontmatter?.mobileComponents?.[0] &&
                frontmatter.mobileComponents.map((component, index) => {
                  const key = `section-${index}`;

                  let componentJSX;

                  switch (component.type) {
                    case `mixedCarousel`:
                      if (!component?.carouselItem?.[0]) {
                        componentJSX = <></>;
                      } else {
                        componentJSX = (
                          <section
                            key={key}
                            className="animation-appear animation-delay-5 relative mt-4 mb-4"
                          >
                            <div className="animation-appear animation-delay-5 grid">
                              <div className="grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative">
                                <SwiperCarousel
                                  className="w-full h-full relative block"
                                  options={{
                                    autoplay: {
                                      delay: 2200
                                    }
                                  }}
                                  slides={component.carouselItem.map(
                                    (item, itemIndex) => {
                                      const itemkey = `${key}-carousel-${itemIndex}`;

                                      return (
                                        <li
                                          key={itemkey}
                                          className="w-full h-full relative block"
                                        >
                                          {(item?.carouselImage?.childImageSharp
                                            ?.fluid && (
                                            <Img
                                              className="w-full h-full relative block object-cover"
                                              fluid={
                                                item.carouselImage
                                                  .childImageSharp.fluid
                                              }
                                              alt="Carousel Item"
                                            />
                                          )) || (
                                            <>
                                              {item && item.carouselVideoUrl && (
                                                <video
                                                  className="w-full h-full relative block object-cover"
                                                  autoPlay
                                                  loop
                                                  muted
                                                  playsInline
                                                >
                                                  <source
                                                    src={item.carouselVideoUrl}
                                                  />
                                                </video>
                                              )}
                                            </>
                                          )}
                                        </li>
                                      );
                                    }
                                  )}
                                />
                              </div>
                            </div>
                          </section>
                        );
                      }

                      break;

                    case `simpleImage`:
                      componentJSX = (
                        <section
                          key={key}
                          className="animation-appear animation-delay-5 relative mt-4 mb-4"
                        >
                          <div className="grid">
                            <figure className="grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative">
                              {component?.simpleImageSource?.childImageSharp
                                ?.fluid && (
                                <Img
                                  className="w-full relative block"
                                  fluid={
                                    component.simpleImageSource.childImageSharp
                                      .fluid
                                  }
                                  alt="Gallery Item"
                                />
                              )}
                            </figure>
                          </div>
                        </section>
                      );

                      break;

                    case `simpleText`:
                      componentJSX = (
                        <section
                          key={key}
                          className={`animation-appear-right animation-delay-2 relative pt-12 pb-12 text-${component.simpleTextFontColor}`}
                          style={{
                            backgroundColor: component.simpleTextBackgroundColor
                          }}
                        >
                          <article className="grid">
                            {component.simpleTextHeading && (
                              <h3 className="grid-end-8 grid-start-4 md:grid-end-10 sm:grid-end-22 sm:grid-start-2 f1">
                                {component.simpleTextHeading}
                              </h3>
                            )}

                            {component.simpleTextContent && (
                              <div className="grid-end-8 grid-start-4 md:grid-end-10 sm:grid-end-22 sm:grid-start-2 mt-12 f4">
                                {this.splitStringToParagraphs(
                                  component.simpleTextContent
                                )}
                              </div>
                            )}
                          </article>
                        </section>
                      );

                      break;

                    case `largeText`:
                      componentJSX = (
                        <section
                          key={key}
                          className="animation-appear animation-delay-5 relative mt-24 sm:mt-12 mb-48 sm:mb-12"
                        >
                          <article className="grid">
                            {component.largeTextHeading && (
                              <h3 className="grid-end-10 grid-start-5 sm:grid-end-22 sm:grid-start-2 mb-4 b1">
                                — {component.largeTextHeading}
                              </h3>
                            )}

                            {component.largeTextContent && (
                              <h2
                                className="grid-end-16 grid-start-4 sm:grid-end-22 sm:grid-start-2 f1 whitespace-pre-wrap"
                                dangerouslySetInnerHTML={{
                                  __html: component.largeTextContent
                                }}
                              ></h2>
                            )}

                            {component.largeTextSubheading && (
                              <h3 className="grid-end-4 grid-start-21 sm:grid-end-22 sm:grid-start-2 flex items-end sm:mt-12 b2">
                                {component.largeTextSubheading}
                              </h3>
                            )}
                          </article>
                        </section>
                      );

                      break;

                    case `video`:
                      componentJSX = (
                        <section
                          key={key}
                          className="animation-appear animation-delay-5 relative mt-4 mb-4"
                        >
                          <div className="grid">
                            <video
                              className="animation-appear-right animation-delay-5 grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative"
                              autoPlay
                              loop
                              muted
                              playsInline
                            >
                              <source src={component.videoUrl} />
                            </video>
                          </div>
                        </section>
                      );

                      break;

                    default:
                      componentJSX = <></>;
                      break;
                  }

                  return componentJSX;
                })}
            </div>
          )}

          <Newsletter />

          <GetInTouch />

          <Footer />
        </Layout>
      </>
    );
  }
}

//

const NowThemPage = ({ data, location }) => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;

  return (
    <NowThemPageComponent
      appContext={appContext}
      documentContext={documentContext}
      frontmatter={frontmatter}
      location={location}
    />
  );
};

export default NowThemPage;

export const query = graphql`
  query NowThemPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading

        components {
          type

          simpleImageSource {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }

          carouselItem {
            carouselImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }

            carouselVideoUrl
          }

          largeTextContent
          largeTextHeading
          largeTextSubheading

          simpleTextBackgroundColor
          simpleTextFontColor
          simpleTextHeading
          simpleTextContent

          sideTextLeft
          sideTextRight
          videoUrl
        }

        mobileComponents {
          type

          simpleImageSource {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }

          carouselItem {
            carouselImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }

            carouselVideoUrl
          }

          largeTextContent
          largeTextHeading
          largeTextSubheading

          simpleTextBackgroundColor
          simpleTextFontColor
          simpleTextHeading
          simpleTextContent

          sideTextLeft
          sideTextRight

          videoUrl
        }

        seoDescription
        seoKeywords
      }
    }
  }
`;
